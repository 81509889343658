<template>
     <div>
         <a-page-header
            :title="$t('product.list.titel')"
            class="header_title"
        />
         <router-view></router-view>
        <div class='box_content orders'>
          <a-form layout="inline" :form="form" @submit="searchSubmit">

            <a-form-item :style="{ display: 'inline-block', width: 'calc(150px - 12px)' }">
              <a-input :placeholder="$t('product.list.search.sku')" v-decorator="['sku']"/>
            </a-form-item>
            
            <a-form-item :style="{ display: 'inline-block', width: 'calc(150px - 12px)' }">
              <a-input :placeholder="$t('product.list.search.product_name')" v-decorator="['title']"/>
            </a-form-item>

            <a-form-item :style="{ display: 'inline-block', width: 'calc(150px - 12px)' }">
              <a-select style="width: 150px" :placeholder="$t('product.list.search.condition')" v-decorator="['condition']">
                  <a-select-option value="0">{{$t('product.list.search.all')}}</a-select-option>
                  <a-select-option value="1">{{$t('inventory.search.pre_owned')}}</a-select-option>
                  <a-select-option value="2">{{$t('inventory.search.used')}}</a-select-option>
                  <a-select-option value="3">{{$t('inventory.search.brand_new')}}</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item>
              <a-button class="ant-btn-danger" type="primary" html-type="submit">{{$t('product.list.search.search')}}</a-button>
            </a-form-item>
          </a-form>

          <a-button @click="addProductBtn" type="danger">
            {{$t('product.list.btn_1')}}
          </a-button>
          <!-- &nbsp;&nbsp;&nbsp;&nbsp;
          <a-button @click="listProductBtn" type="danger">
            {{$t('product.list.btn_2')}}
          </a-button> -->

          <a-tabs default-active-key="0" @change="callback" :animated="false" size="large">
            <a-tab-pane key="0" :tab="$t('product.list.tab_1')"></a-tab-pane>
            <a-tab-pane key="1" :tab="$t('product.list.tab_3')" force-render></a-tab-pane>
            <!-- <a-tab-pane key="1" tab="Gyroor" force-render></a-tab-pane> -->
            <a-tab-pane key="2" tab="DataHunt">
              <!-- <a-button @click="addOrderBtn">CREATE MANUAL ORDER</a-button> -->
            </a-tab-pane>
            <a-tab-pane key="5" tab="Microsel"></a-tab-pane>
            <!-- <a-tab-pane key="3" tab="Return Helper"></a-tab-pane> -->
            <!-- <a-tab-pane key="4" tab="SZ Warehouse"></a-tab-pane> -->
          </a-tabs>
            <a-table 
            @change="pageChange" 
            :pagination="pagination" 
            :rowKey="(record, index) => index" 
            :columns="columns" 
            :data-source="productData" 
            :loading="loading"
            size="middle"
            >
              <img class="proimg" slot="main_img" slot-scope="text,data" :src='text' alt="error" @click="action_product(data.id)"/>
              <div slot="is_published" slot-scope="text">{{text}}</div>
                <a-tag
                  slot="is_published" slot-scope="text,data,index"
                  :key="text"
                  :color="'geekblue'"
                  @click="publishProductBtn(data.id,index)"
                >
                  {{statusText(text)}}
                </a-tag>
                <a-tag
                  slot="condition_status" slot-scope="text"
                  :key="text"
                  :color="'geekblue'"
                >
                  {{$t(condition_status_text(text))}}
                </a-tag>
                <span slot="product_id" slot-scope="text,data,index">
                    <!-- <a @click="copyBtn(data.id)">Copy</a> -->
                    <a @click="editBtn(data.id)">{{$t('action.edit')}}</a>
                    <a-divider type="vertical" />
                    <!-- <a @click="deleteBtn(data.product_id,index)"><a-icon type="delete" /></a> -->
                    <a-popconfirm
                      :title="$t('message.delete')"
                      :ok-text="$t('message.btn_ok')"
                      :cancel-text="$t('message.btn_no')"
                      @confirm="deleteBtn(data.id,index)"
                      @cancel="cancel"
                    ><a href="javascript:void(0)">{{$t('action.delete')}}</a></a-popconfirm>
                </span>
          </a-table>
    </div>
    </div>
</template>

<script>

import {
  add,
  del,
  getList,
} from "@/network/products2";

export default {
  name: "ProductList",
  components: {},
  directives: {},
  data() {
    return {
      form: this.$form.createForm(this, { name: 'coordinated' }),
      loading: false,
      productData: [],
      pagination: { defaultPageSize: 15, current: 1, total: 0 },
      condition:'0',
      sku:'',
      title:'',
      parameter:{},
      Warehouse:0,
    };
  },
  created() {
    this.getProductsData(1);
  },

  mounted() {},
  computed: {
    columns(){
      return [
        {
          title: this.$t('product.list.table.head_titel_1'),
          key: "img",
          dataIndex: "img",
          scopedSlots: { customRender: "main_img" },
        },
        {
          title: this.$t('product.list.table.head_titel_2'),
          key: "title",
          dataIndex: "title",
          scopedSlots: { customRender: "title" },
        },
        {
          title: this.$t('product.list.table.head_titel_3'),
          key: "sku",
          dataIndex: "sku",
        },{
          title: this.$t('product.list.table.head_titel_4'),
          key: "cost",
          dataIndex: "cost",
        },{
          title: this.$t('product.list.table.head_titel_5'),
          key: "unit",
          dataIndex: "unit",
        },
        {
          title: this.$t('product.list.table.head_titel_6'),
          dataIndex: 'weight_kg',
          key: 'weight_kg'
        },{
          title: this.$t('product.list.table.head_titel_7'),
          dataIndex: 'length_cm',
          key: 'length_cm'
        },{
          title: this.$t('product.list.table.head_titel_8'),
          dataIndex: 'width_cm',
          key: 'width_cm',
        },{
          title: this.$t('product.list.table.head_titel_9'),
          dataIndex: 'height_cm',
          key: 'height_cm',
        },
        {
          title: this.$t('product.list.table.head_titel_10'),
          dataIndex: 'condition',
          key: 'condition',
          scopedSlots: { customRender: 'condition_status' },
        },
        {
          title: this.$t('product.list.table.head_titel_11'),
          key: "product_id",
          dataIndex: "product_id",
          scopedSlots: { customRender: "product_id" },
        },
      ];
    }
  },
  methods: {
    callback(key) {
      console.log(key);
      this.Warehouse = key;
      this.getProductsData(1);
    },
    cancel(){

    },
    searchSubmit(e){
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(values);
        this.parameter = {
            sku:values.sku,
            condition:values.condition,
            title:values.title
        };
        this.getProductsData(1);
      });
    },
    pageChange(pagination, filters, sorter) {
      console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.getProductsData(pagination.current);
    },
    getProductsData(page) {
      this.loading = true;
      this.parameter.Warehouse = this.Warehouse;
      getList(page,this.parameter).then((res) => {
        if (res.code == 0) {
           console.log(res);
          this.$set(this.pagination, "total", res.total);
          this.productData = res.data;
          this.loading = false;
        }
        // this.$message.info(res.header.msg);
      });
    },
    addProductBtn() {
      this.$router.push("/products2/edit");
    },
    listProductBtn() {
      this.$router.push("/products2/browse");
    },
    //删除
    deleteBtn(e, index) {
      del(e).then((res) => {
        if (res.code == 0) {
          this.productData.splice(index, 1);
          this.$message.success("delete success!");
        }
      });
    },
    //编辑
    editBtn(e) {
      // console.log(e)
      this.$router.push({
        path: `/products2/edit`,
        query: { product_id: e },
      });
    },
    condition_status_text(e){
      if(e == '1'){
          // return 'Brand New';
          // return 'Pre-Owned';
          return 'inventory.search.pre_owned';
        } else 
        if(e == '2'){
          // return 'UN - Unknown';
          // return 'Used';
          return 'inventory.search.used';
        } else 
        if(e == '3'){
          // return 'Brand New';
          return 'inventory.search.brand_new';
          // return 'Refurbushed 90P';
        } else 
        if(e == '4'){
          // return 'Refurbushed 80P';
        } else 
        if(e == '5'){
          // return 'Scrap';
        }
    },
  },
};

</script>

<style  scoped>
.orders .wrap_order {
  box-shadow: 0 0 10px rgba(143, 144, 165, 0.7);
  padding: 15px 0;
  overflow-x: auto;
}
.orders .ordertable {
  width: 100%;
  border-spacing: 0;
}
.orders .ordertable thead {
  border-bottom: 1px solid #e5e5ec;
}
.orders .ordertable th {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  padding: 0 12px 12px;
  line-height: 20px;
  color: #272742;
}
.orders .ordertable td {
  padding: 12px;
  white-space: nowrap;
  font-size: 14px;
  color: #666666;
}
.orders .ordertable .td_black {
  font-weight: 550;
}
.orders .ordertable tr {
  border-bottom: 1px solid #e5e5ec;
  cursor: pointer;
}
.orders .ordertable tr .paytosupplierbtn {
  background-color: #ff1053;
  color: #ffffff;
}

.orders .proimg {
  width: 50px;
  height: 50px;
  cursor:pointer;
}
</style>