import {request} from './request_loc'
import Cookies from 'js-cookie'
import qs from 'qs'

export function add(data) {
    data.maker_id = Cookies.get("maker_id");
    return request({url:'/products2/add',method:'post',data:qs.stringify(data)});
}
export function del(product_id) {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/products2/del',params:{product_id,maker_id}});
}
export function edit(data) {
    data.maker_id = Cookies.get("maker_id");
    return request({url:'/products2/edit',method:'post',data:qs.stringify(data)});
}
export function getList(page,params) {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/products2/getList?page='+page+'&maker_id='+maker_id,method:'post',data:qs.stringify(params)})
}
export function getDetail(product_id) {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/products2/getDetail',params:{maker_id,product_id}});
}
export function browseSetSubmit() {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/products2/browseSetSubmit',params:{maker_id}});
}
export function browseSetSubmit2() {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/products2/browseSetSubmit2',params:{maker_id}});
}
export function getStatus() {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/products2/getStatus',params:{maker_id}});
}